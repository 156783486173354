/**
 * Tailwind css imports
 *
 * @author: exode <hello@exode.ru>
 */

.container {

    width: 100%
}

@media (min-width: 350px) {

    .container {

        max-width: 350px
    }
}

@media (min-width: 640px) {

    .container {

        max-width: 640px
    }
}

@media (min-width: 768px) {

    .container {

        max-width: 768px
    }
}

@media (min-width: 1024px) {

    .container {

        max-width: 1024px
    }
}

@media (min-width: 1209px) {

    .container {

        max-width: 1209px
    }
}

@media (min-width: 1280px) {

    .container {

        max-width: 1280px
    }
}

@media (min-width: 1536px) {

    .container {

        max-width: 1536px
    }
}
.pointer-events-none {

    pointer-events: none
}
.pointer-events-auto {

    pointer-events: auto
}
.visible {

    visibility: visible
}
.\!visible {

    visibility: visible !important
}
.invisible {

    visibility: hidden
}
.collapse {

    visibility: collapse
}
.static {

    position: static
}
.\!static {

    position: static !important
}
.fixed {

    position: fixed
}
.absolute {

    position: absolute
}
.\!absolute {

    position: absolute !important
}
.relative {

    position: relative
}
.sticky {

    position: sticky
}
.left-0 {

    left: 0px
}
.top-0 {

    top: 0px
}
.top-\[4px\] {

    top: 4px
}
.left-\[4px\] {

    left: 4px
}
.left-2 {

    left: 0.5rem
}
.top-2\.5 {

    top: 0.625rem
}
.top-2 {

    top: 0.5rem
}
.right-2 {

    right: 0.5rem
}
.bottom-2\.5 {

    bottom: 0.625rem
}
.bottom-2 {

    bottom: 0.5rem
}
.bottom-0 {

    bottom: 0px
}
.top-\[50\%\] {

    top: 50%
}
.left-\[50\%\] {

    left: 50%
}
.right-0 {

    right: 0px
}
.\!left-\[-45px\] {

    left: -45px !important
}
.top-4 {

    top: 1rem
}
.bottom-4 {

    bottom: 1rem
}
.left-\[40px\] {

    left: 40px
}
.\!top-\[45px\] {

    top: 45px !important
}
.\!right-\[10px\] {

    right: 10px !important
}
.\!top-\[12px\] {

    top: 12px !important
}
.bottom-\[5px\] {

    bottom: 5px
}
.left-\[5px\] {

    left: 5px
}
.top-\[5px\] {

    top: 5px
}
.right-\[-10px\] {

    right: -10px
}
.top-\[-10px\] {

    top: -10px
}
.right-3 {

    right: 0.75rem
}
.left-\[10px\] {

    left: 10px
}
.top-\[10px\] {

    top: 10px
}
.right-\[1px\] {

    right: 1px
}
.bottom-\[1px\] {

    bottom: 1px
}
.top-\[0px\] {

    top: 0px
}
.right-\[5px\] {

    right: 5px
}
.left-\[3px\] {

    left: 3px
}
.top-\[3px\] {

    top: 3px
}
.top-\[-1px\] {

    top: -1px
}
.right-\[-6px\] {

    right: -6px
}
.top-\[calc\(100\%_-_12px\)\] {

    top: calc(100% - 12px)
}
.right-\[20px\] {

    right: 20px
}
.bottom-\[25px\] {

    bottom: 25px
}
.top-\[52px\] {

    top: 52px
}
.top-\[-0\.003em\] {

    top: -0.003em
}
.left-2\.5 {

    left: 0.625rem
}
.top-\[-60px\] {

    top: -60px
}
.right-4 {

    right: 1rem
}
.right-6 {

    right: 1.5rem
}
.bottom-\[30px\] {

    bottom: 30px
}
.top-\[-3px\] {

    top: -3px
}
.bottom-\[6px\] {

    bottom: 6px
}
.bottom-\[40px\] {

    bottom: 40px
}
.right-\[4px\] {

    right: 4px
}
.top-\[11px\] {

    top: 11px
}
.-right-2 {

    right: -0.5rem
}
.top-1 {

    top: 0.25rem
}
.bottom-5 {

    bottom: 1.25rem
}
.\!top-\[23px\] {

    top: 23px !important
}
.right-\[10px\] {

    right: 10px
}
.top-\[2px\] {

    top: 2px
}
.top-\[20px\] {

    top: 20px
}
.left-4 {

    left: 1rem
}
.bottom-\[-1px\] {

    bottom: -1px
}
.-right-1 {

    right: -0.25rem
}
.-top-1 {

    top: -0.25rem
}
.top-\[18px\] {

    top: 18px
}
.left-\[18px\] {

    left: 18px
}
.z-10 {

    z-index: 10
}
.z-\[2\] {

    z-index: 2
}
.z-\[1\] {

    z-index: 1
}
.z-0 {

    z-index: 0
}
.z-\[20\] {

    z-index: 20
}
.z-\[100000\] {

    z-index: 100000
}
.z-\[10000\] {

    z-index: 10000
}
.z-\[100\] {

    z-index: 100
}
.z-20 {

    z-index: 20
}
.z-\[101\] {

    z-index: 101
}
.\!z-\[1002\] {

    z-index: 1002 !important
}
.z-30 {

    z-index: 30
}
.z-50 {

    z-index: 50
}
.\!z-0 {

    z-index: 0 !important
}
.z-\[3\] {

    z-index: 3
}
.m-auto {

    margin: auto
}
.m-0 {

    margin: 0px
}
.m-2 {

    margin: 0.5rem
}
.m-5 {

    margin: 1.25rem
}
.\!m-3 {

    margin: 0.75rem !important
}
.\!m-0 {

    margin: 0px !important
}
.\!m-4 {

    margin: 1rem !important
}
.m-6 {

    margin: 1.5rem
}
.my-2 {

    margin-top: 0.5rem;

    margin-bottom: 0.5rem
}
.mx-2 {

    margin-left: 0.5rem;

    margin-right: 0.5rem
}
.mx-auto {

    margin-left: auto;

    margin-right: auto
}
.my-1 {

    margin-top: 0.25rem;

    margin-bottom: 0.25rem
}
.my-5 {

    margin-top: 1.25rem;

    margin-bottom: 1.25rem
}
.my-4 {

    margin-top: 1rem;

    margin-bottom: 1rem
}
.mx-4 {

    margin-left: 1rem;

    margin-right: 1rem
}
.my-auto {

    margin-top: auto;

    margin-bottom: auto
}
.\!my-auto {

    margin-top: auto !important;

    margin-bottom: auto !important
}
.mx-1\.5 {

    margin-left: 0.375rem;

    margin-right: 0.375rem
}
.mx-1 {

    margin-left: 0.25rem;

    margin-right: 0.25rem
}
.my-10 {

    margin-top: 2.5rem;

    margin-bottom: 2.5rem
}
.my-3 {

    margin-top: 0.75rem;

    margin-bottom: 0.75rem
}
.mx-3 {

    margin-left: 0.75rem;

    margin-right: 0.75rem
}
.mx-2\.5 {

    margin-left: 0.625rem;

    margin-right: 0.625rem
}
.my-3\.5 {

    margin-top: 0.875rem;

    margin-bottom: 0.875rem
}
.-mx-2 {

    margin-left: -0.5rem;

    margin-right: -0.5rem
}
.my-\[6px\] {

    margin-top: 6px;

    margin-bottom: 6px
}
.mx-0 {

    margin-left: 0px;

    margin-right: 0px
}
.mx-12 {

    margin-left: 3rem;

    margin-right: 3rem
}
.my-0\.5 {

    margin-top: 0.125rem;

    margin-bottom: 0.125rem
}
.my-0 {

    margin-top: 0px;

    margin-bottom: 0px
}
.my-6 {

    margin-top: 1.5rem;

    margin-bottom: 1.5rem
}
.-mx-4 {

    margin-left: -1rem;

    margin-right: -1rem
}
.mx-8 {

    margin-left: 2rem;

    margin-right: 2rem
}
.\!mx-0 {

    margin-left: 0px !important;

    margin-right: 0px !important
}
.mb-2 {

    margin-bottom: 0.5rem
}
.\!-mt-2 {

    margin-top: -0.5rem !important
}
.mr-2 {

    margin-right: 0.5rem
}
.ml-2 {

    margin-left: 0.5rem
}
.mb-4 {

    margin-bottom: 1rem
}
.mb-0\.5 {

    margin-bottom: 0.125rem
}
.mb-0 {

    margin-bottom: 0px
}
.mt-2 {

    margin-top: 0.5rem
}
.mt-5 {

    margin-top: 1.25rem
}
.mr-3 {

    margin-right: 0.75rem
}
.mt-20 {

    margin-top: 5rem
}
.mt-10 {

    margin-top: 2.5rem
}
.mt-4 {

    margin-top: 1rem
}
.-ml-2 {

    margin-left: -0.5rem
}
.mb-1 {

    margin-bottom: 0.25rem
}
.ml-7 {

    margin-left: 1.75rem
}
.mt-6 {

    margin-top: 1.5rem
}
.mt-1 {

    margin-top: 0.25rem
}
.mr-\[14px\] {

    margin-right: 14px
}
.mt-\[-4px\] {

    margin-top: -4px
}
.ml-\[38px\] {

    margin-left: 38px
}
.mr-4 {

    margin-right: 1rem
}
.ml-0\.5 {

    margin-left: 0.125rem
}
.ml-0 {

    margin-left: 0px
}
.\!mt-0 {

    margin-top: 0px !important
}
.mt-3 {

    margin-top: 0.75rem
}
.mt-2\.5 {

    margin-top: 0.625rem
}
.mb-\[1px\] {

    margin-bottom: 1px
}
.mb-3 {

    margin-bottom: 0.75rem
}
.ml-1 {

    margin-left: 0.25rem
}
.mt-8 {

    margin-top: 2rem
}
.-ml-\[0\.04em\] {

    margin-left: -0.04em
}
.-mr-\[0\.04em\] {

    margin-right: -0.04em
}
.\!mr-\[8px\] {

    margin-right: 8px !important
}
.mt-\[25px\] {

    margin-top: 25px
}
.mt-0\.5 {

    margin-top: 0.125rem
}
.mt-0 {

    margin-top: 0px
}
.mt-\[1px\] {

    margin-top: 1px
}
.mt-\[-1px\] {

    margin-top: -1px
}
.mr-1 {

    margin-right: 0.25rem
}
.\!mr-2 {

    margin-right: 0.5rem !important
}
.mb-auto {

    margin-bottom: auto
}
.mt-14 {

    margin-top: 3.5rem
}
.-ml-12 {

    margin-left: -3rem
}
.mt-7 {

    margin-top: 1.75rem
}
.mt-9 {

    margin-top: 2.25rem
}
.mr-\[-30px\] {

    margin-right: -30px
}
.mr-0\.5 {

    margin-right: 0.125rem
}
.mr-0 {

    margin-right: 0px
}
.mb-8 {

    margin-bottom: 2rem
}
.mb-2\.5 {

    margin-bottom: 0.625rem
}
.ml-4 {

    margin-left: 1rem
}
.-mt-1 {

    margin-top: -0.25rem
}
.mb-1\.5 {

    margin-bottom: 0.375rem
}
.-mr-0\.5 {

    margin-right: -0.125rem
}
.-mt-0\.5 {

    margin-top: -0.125rem
}
.-mr-0 {

    margin-right: -0px
}
.-mt-0 {

    margin-top: -0px
}
.mt-\[10px\] {

    margin-top: 10px
}
.mb-6 {

    margin-bottom: 1.5rem
}
.-ml-1\.5 {

    margin-left: -0.375rem
}
.-ml-1 {

    margin-left: -0.25rem
}
.mb-5 {

    margin-bottom: 1.25rem
}
.\!mt-12 {

    margin-top: 3rem !important
}
.mt-\[3px\] {

    margin-top: 3px
}
.\!mt-\[12px\] {

    margin-top: 12px !important
}
.\!mb-\[3px\] {

    margin-bottom: 3px !important
}
.mt-\[22px\] {

    margin-top: 22px
}
.\!mr-4 {

    margin-right: 1rem !important
}
.\!mt-4 {

    margin-top: 1rem !important
}
.\!mt-2\.5 {

    margin-top: 0.625rem !important
}
.\!mt-2 {

    margin-top: 0.5rem !important
}
.\!ml-4 {

    margin-left: 1rem !important
}
.mb-\[0\.425rem\] {

    margin-bottom: 0.425rem
}
.mb-10 {

    margin-bottom: 2.5rem
}
.mt-12 {

    margin-top: 3rem
}
.\!mb-auto {

    margin-bottom: auto !important
}
.\!mt-\[-7px\] {

    margin-top: -7px !important
}
.mt-\[-25px\] {

    margin-top: -25px
}
.\!mb-4 {

    margin-bottom: 1rem !important
}
.ml-3 {

    margin-left: 0.75rem
}
.\!mt-1\.5 {

    margin-top: 0.375rem !important
}
.\!mt-1 {

    margin-top: 0.25rem !important
}
.\!mt-\[-15px\] {

    margin-top: -15px !important
}
.\!mt-auto {

    margin-top: auto !important
}
.\!mb-\[-16px\] {

    margin-bottom: -16px !important
}
.\!mb-\[-28px\] {

    margin-bottom: -28px !important
}
.mt-\[-20px\] {

    margin-top: -20px
}
.\!mt-5 {

    margin-top: 1.25rem !important
}
.\!mt-8 {

    margin-top: 2rem !important
}
.\!mt-\[-10px\] {

    margin-top: -10px !important
}
.\!mt-\[-5px\] {

    margin-top: -5px !important
}
.-mb-1 {

    margin-bottom: -0.25rem
}
.mt-3\.5 {

    margin-top: 0.875rem
}
.\!-mt-1 {

    margin-top: -0.25rem !important
}
.mt-\[5px\] {

    margin-top: 5px
}
.mb-\[5px\] {

    margin-bottom: 5px
}
.-mt-3 {

    margin-top: -0.75rem
}
.\!mt-\[18px\] {

    margin-top: 18px !important
}
.mt-auto {

    margin-top: auto
}
.mt-\[2px\] {

    margin-top: 2px
}
.ml-\[2px\] {

    margin-left: 2px
}
.ml-3\.5 {

    margin-left: 0.875rem
}
.mt-1\.5 {

    margin-top: 0.375rem
}
.\!mr-3 {

    margin-right: 0.75rem !important
}
.ml-\[1px\] {

    margin-left: 1px
}
.mb-\[2px\] {

    margin-bottom: 2px
}
.\!mt-\[5px\] {

    margin-top: 5px !important
}
.-mr-1 {

    margin-right: -0.25rem
}
.mt-\[0\.003em\] {

    margin-top: 0.003em
}
.\!mt-\[-8px\] {

    margin-top: -8px !important
}
.\!mr-1\.5 {

    margin-right: 0.375rem !important
}
.\!mr-1 {

    margin-right: 0.25rem !important
}
.mr-auto {

    margin-right: auto
}
.ml-11 {

    margin-left: 2.75rem
}
.\!ml-auto {

    margin-left: auto !important
}
.ml-5 {

    margin-left: 1.25rem
}
.mb-7 {

    margin-bottom: 1.75rem
}
.mb-16 {

    margin-bottom: 4rem
}
.mt-32 {

    margin-top: 8rem
}
.mt-36 {

    margin-top: 9rem
}
.mt-16 {

    margin-top: 4rem
}
.mt-24 {

    margin-top: 6rem
}
.ml-\[12\.5px\] {

    margin-left: 12.5px
}
.-mr-2 {

    margin-right: -0.5rem
}
.mb-12 {

    margin-bottom: 3rem
}
.ml-auto {

    margin-left: auto
}
.mb-14 {

    margin-bottom: 3.5rem
}
.-mr-3 {

    margin-right: -0.75rem
}
.mr-2\.5 {

    margin-right: 0.625rem
}
.mt-\[4px\] {

    margin-top: 4px
}
.ml-\[2\.75rem\] {

    margin-left: 2.75rem
}
.mr-\[20px\] {

    margin-right: 20px
}
.mr-1\.5 {

    margin-right: 0.375rem
}
.mb-\[18px\] {

    margin-bottom: 18px
}
.mb-\[40px\] {

    margin-bottom: 40px
}
.mb-\[calc\(0\.5rem_\+_3px\)\] {

    margin-bottom: calc(0.5rem + 3px)
}
.ml-1\.5 {

    margin-left: 0.375rem
}
.box-border {

    box-sizing: border-box
}
.block {

    display: block
}
.\!block {

    display: block !important
}
.inline-block {

    display: inline-block
}
.inline {

    display: inline
}
.flex {

    display: flex
}
.\!flex {

    display: flex !important
}
.inline-flex {

    display: inline-flex
}
.table {

    display: table
}
.grid {

    display: grid
}
.contents {

    display: contents
}
.hidden {

    display: none
}
.\!hidden {

    display: none !important
}
.aspect-\[1\/1\] {

    aspect-ratio: 1/1
}
.h-auto {

    height: auto
}
.h-\[60px\] {

    height: 60px
}
.h-\[260px\] {

    height: 260px
}
.h-full {

    height: 100%
}
.h-\[calc\(100\%_-_8px\)\] {

    height: calc(100% - 8px)
}
.h-\[15px\] {

    height: 15px
}
.h-\[32px\] {

    height: 32px
}
.h-\[250px\] {

    height: 250px
}
.h-\[100vh\] {

    height: 100vh
}
.h-\[calc\(var\(--app-height\)_-_50px\)\] {

    height: calc(var(--app-height) - 50px)
}
.h-\[calc\(100\%_-_16px\)\] {

    height: calc(100% - 16px)
}
.h-2 {

    height: 0.5rem
}
.h-\[1px\] {

    height: 1px
}
.h-\[40px\] {

    height: 40px
}
.h-\[115px\] {

    height: 115px
}
.h-6 {

    height: 1.5rem
}
.h-\[36px\] {

    height: 36px
}
.h-\[30px\] {

    height: 30px
}
.h-\[calc\(100\%_-_52px\)\] {

    height: calc(100% - 52px)
}
.h-\[150px\] {

    height: 150px
}
.h-\[calc\(100vh_-_95px\)\] {

    height: calc(100vh - 95px)
}
.h-\[20px\] {

    height: 20px
}
.h-\[152px\] {

    height: 152px
}
.\!h-\[13px\] {

    height: 13px !important
}
.h-\[42px\] {

    height: 42px
}
.\!h-\[135px\] {

    height: 135px !important
}
.h-\[110px\] {

    height: 110px
}
.h-\[80px\] {

    height: 80px
}
.h-\[8px\] {

    height: 8px
}
.h-\[var\(--panelheader\\_height\)\] {

    height: var(--panelheader_height)
}
.h-\[100dvh\] {

    height: 100dvh
}
.h-\[calc\(100\%_-_44px\)\] {

    height: calc(100% - 44px)
}
.h-\[130px\] {

    height: 130px
}
.h-\[var\(--tabbar\\_height\)\] {

    height: var(--tabbar_height)
}
.h-\[calc\(100\%_-_56px\)\] {

    height: calc(100% - 56px)
}
.h-\[calc\(100\%_-_54px\)\] {

    height: calc(100% - 54px)
}
.h-4 {

    height: 1rem
}
.h-\[70px\] {

    height: 70px
}
.h-\[400px\] {

    height: 400px
}
.\!h-\[400px\] {

    height: 400px !important
}
.\!h-\[160px\] {

    height: 160px !important
}
.h-\[5px\] {

    height: 5px
}
.h-\[16px\] {

    height: 16px
}
.h-\[64px\] {

    height: 64px
}
.h-\[362px\] {

    height: 362px
}
.h-\[176px\] {

    height: 176px
}
.h-\[62px\] {

    height: 62px
}
.h-\[85px\] {

    height: 85px
}
.h-80 {

    height: 20rem
}
.h-\[184px\] {

    height: 184px
}
.h-\[140px\] {

    height: 140px
}
.max-h-2\.5 {

    max-height: 0.625rem
}
.max-h-2 {

    max-height: 0.5rem
}
.max-h-\[49px\] {

    max-height: 49px
}
.max-h-\[77px\] {

    max-height: 77px
}
.max-h-\[250px\] {

    max-height: 250px
}
.max-h-\[300px\] {

    max-height: 300px
}
.min-h-full {

    min-height: 100%
}
.min-h-\[calc\(100vh_-_400px\)\] {

    min-height: calc(100vh - 400px)
}
.min-h-\[calc\(100vh_-_500px\)\] {

    min-height: calc(100vh - 500px)
}
.min-h-\[40px\] {

    min-height: 40px
}
.min-h-0 {

    min-height: 0px
}
.min-h-\[250px\] {

    min-height: 250px
}
.min-h-\[120px\] {

    min-height: 120px
}
.min-h-\[75px\] {

    min-height: 75px
}
.min-h-\[28px\] {

    min-height: 28px
}
.min-h-\[12px\] {

    min-height: 12px
}
.min-h-\[100px\] {

    min-height: 100px
}
.min-h-\[140px\] {

    min-height: 140px
}
.min-h-\[24px\] {

    min-height: 24px
}
.min-h-\[44px\] {

    min-height: 44px
}
.min-h-\[450px\] {

    min-height: 450px
}
.min-h-\[300px\] {

    min-height: 300px
}
.min-h-\[144px\] {

    min-height: 144px
}
.min-h-\[calc\(100vh_-_435px\)\] {

    min-height: calc(100vh - 435px)
}
.min-h-\[calc\(100vh_-_416px\)\] {

    min-height: calc(100vh - 416px)
}
.min-h-\[74px\] {

    min-height: 74px
}
.min-h-\[56px\] {

    min-height: 56px
}
.\!min-h-\[40px\] {

    min-height: 40px !important
}
.min-h-\[350px\] {

    min-height: 350px
}
.min-h-\[545px\] {

    min-height: 545px
}
.min-h-\[42px\] {

    min-height: 42px
}
.min-h-\[calc\(100vh_-_550px\)\] {

    min-height: calc(100vh - 550px)
}
.min-h-\[36px\] {

    min-height: 36px
}
.min-h-\[35px\] {

    min-height: 35px
}
.w-full {

    width: 100%
}
.w-\[calc\(100\%_-_8px\)\] {

    width: calc(100% - 8px)
}
.w-\[155px\] {

    width: 155px
}
.w-\[calc\(100\%_-_16px\)\] {

    width: calc(100% - 16px)
}
.w-2 {

    width: 0.5rem
}
.w-\[95px\] {

    width: 95px
}
.w-\[calc\(100\%_-_128px\)\] {

    width: calc(100% - 128px)
}
.w-\[calc\(100\%_-_32px\)\] {

    width: calc(100% - 32px)
}
.w-\[197px\] {

    width: 197px
}
.w-\[30px\] {

    width: 30px
}
.w-\[calc\(100\%_-_56px\)\] {

    width: calc(100% - 56px)
}
.w-\[calc\(100\%_-_1em\)\] {

    width: calc(100% - 1em)
}
.w-\[20px\] {

    width: 20px
}
.w-\[95\%\] {

    width: 95%
}
.w-\[110px\] {

    width: 110px
}
.w-\[116px\] {

    width: 116px
}
.w-\[240px\] {

    width: 240px
}
.w-2\/6 {

    width: 33.333333%
}
.w-\[32px\] {

    width: 32px
}
.w-auto {

    width: auto
}
.w-max {

    width: max-content
}
.w-\[70px\] {

    width: 70px
}
.w-\[400px\] {

    width: 400px
}
.w-60 {

    width: 15rem
}
.w-\[300px\] {

    width: 300px
}
.w-\[350px\] {

    width: 350px
}
.w-3\/4 {

    width: 75%
}
.w-\[50px\] {

    width: 50px
}
.w-\[200px\] {

    width: 200px
}
.w-10\/12 {

    width: 83.333333%
}
.w-\[210px\] {

    width: 210px
}
.w-\[230px\] {

    width: 230px
}
.w-\[40px\] {

    width: 40px
}
.w-\[55px\] {

    width: 55px
}
.w-\[360px\] {

    width: 360px
}
.w-\[320px\] {

    width: 320px
}
.w-\[42px\] {

    width: 42px
}
.w-\[80\%\] {

    width: 80%
}
.w-\[35px\] {

    width: 35px
}
.w-\[140px\] {

    width: 140px
}
.min-w-\[75px\] {

    min-width: 75px
}
.min-w-\[63px\] {

    min-width: 63px
}
.min-w-\[16px\] {

    min-width: 16px
}
.min-w-\[28px\] {

    min-width: 28px
}
.min-w-\[80px\] {

    min-width: 80px
}
.min-w-\[29px\] {

    min-width: 29px
}
.min-w-\[30px\] {

    min-width: 30px
}
.min-w-\[1px\] {

    min-width: 1px
}
.min-w-\[210px\] {

    min-width: 210px
}
.min-w-\[125px\] {

    min-width: 125px
}
.min-w-\[18px\] {

    min-width: 18px
}
.min-w-\[24px\] {

    min-width: 24px
}
.min-w-\[400px\] {

    min-width: 400px
}
.min-w-\[40px\] {

    min-width: 40px
}
.min-w-\[115px\] {

    min-width: 115px
}
.min-w-\[160px\] {

    min-width: 160px
}
.min-w-\[calc\(100\%_-_95px\)\] {

    min-width: calc(100% - 95px)
}
.\!min-w-\[auto\] {

    min-width: auto !important
}
.min-w-\[20px\] {

    min-width: 20px
}
.min-w-\[116px\] {

    min-width: 116px
}
.min-w-\[120px\] {

    min-width: 120px
}
.min-w-\[135px\] {

    min-width: 135px
}
.min-w-\[300px\] {

    min-width: 300px
}
.min-w-\[42px\] {

    min-width: 42px
}
.min-w-\[60px\] {

    min-width: 60px
}
.min-w-\[35px\] {

    min-width: 35px
}
.\!min-w-\[120px\] {

    min-width: 120px !important
}
.min-w-\[32px\] {

    min-width: 32px
}
.max-w-\[500px\] {

    max-width: 500px
}
.max-w-\[calc\(100\%_-_80px\)\] {

    max-width: calc(100% - 80px)
}
.max-w-\[180px\] {

    max-width: 180px
}
.max-w-\[calc\(100\%_-_95px\)\] {

    max-width: calc(100% - 95px)
}
.max-w-\[80\%\] {

    max-width: 80%
}
.max-w-\[750px\] {

    max-width: 750px
}
.max-w-\[calc\(100\%_-_60px\)\] {

    max-width: calc(100% - 60px)
}
.max-w-full {

    max-width: 100%
}
.max-w-\[376px\] {

    max-width: 376px
}
.max-w-\[70\%\] {

    max-width: 70%
}
.\!max-w-\[1200px\] {

    max-width: 1200px !important
}
.max-w-\[250px\] {

    max-width: 250px
}
.max-w-\[calc\(100\%_-_140px\)\] {

    max-width: calc(100% - 140px)
}
.max-w-lg {

    max-width: 32rem
}
.max-w-\[145px\] {

    max-width: 145px
}
.max-w-\[100\%\] {

    max-width: 100%
}
.max-w-\[calc\(100\%_-_10px\)\] {

    max-width: calc(100% - 10px)
}
.max-w-\[800px\] {

    max-width: 800px
}
.max-w-\[50\%\] {

    max-width: 50%
}
.max-w-\[219px\] {

    max-width: 219px
}
.max-w-\[200px\] {

    max-width: 200px
}
.max-w-\[280px\] {

    max-width: 280px
}
.max-w-\[calc\(100\%_-_42px\)\] {

    max-width: calc(100% - 42px)
}
.max-w-\[calc\(100\%_-_170px\)\] {

    max-width: calc(100% - 170px)
}
.max-w-\[270px\] {

    max-width: 270px
}
.max-w-\[calc\(100\%_-_45px\)\] {

    max-width: calc(100% - 45px)
}
.max-w-\[calc\(100\%_-_35px\)\] {

    max-width: calc(100% - 35px)
}
.flex-1 {

    flex: 1 1 0%
}
.flex-shrink-0 {

    flex-shrink: 0
}
.shrink-0 {

    flex-shrink: 0
}
.flex-grow {

    flex-grow: 1
}
.grow {

    flex-grow: 1
}
.-translate-x-1\/2 {

    --tw-translate-x: -50%;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.-rotate-90 {

    --tw-rotate: -90deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.-rotate-45 {

    --tw-rotate: -45deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.transform {

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.\!transform {

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}
@keyframes ping {

    75%, 100% {

        transform: scale(2);

        opacity: 0
    }
}
.animate-ping {

    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}
.cursor-pointer {

    cursor: pointer
}
.cursor-move {

    cursor: move
}
.\!cursor-default {

    cursor: default !important
}
.cursor-not-allowed {

    cursor: not-allowed
}
.cursor-default {

    cursor: default
}
.cursor-help {

    cursor: help
}
.\!cursor-pointer {

    cursor: pointer !important
}
.select-none {

    -webkit-user-select: none;

            user-select: none
}
.resize {

    resize: both
}
.list-none {

    list-style-type: none
}
.list-disc {

    list-style-type: disc
}
.grid-cols-5 {

    grid-template-columns: repeat(5, minmax(0, 1fr))
}
.grid-cols-3 {

    grid-template-columns: repeat(3, minmax(0, 1fr))
}
.grid-cols-2 {

    grid-template-columns: repeat(2, minmax(0, 1fr))
}
.grid-cols-4 {

    grid-template-columns: repeat(4, minmax(0, 1fr))
}
.grid-cols-1 {

    grid-template-columns: repeat(1, minmax(0, 1fr))
}
.flex-row {

    flex-direction: row
}
.flex-row-reverse {

    flex-direction: row-reverse
}
.flex-col {

    flex-direction: column
}
.flex-col-reverse {

    flex-direction: column-reverse
}
.flex-wrap {

    flex-wrap: wrap
}
.content-start {

    align-content: flex-start
}
.items-start {

    align-items: flex-start
}
.\!items-start {

    align-items: flex-start !important
}
.items-end {

    align-items: flex-end
}
.items-center {

    align-items: center
}
.items-baseline {

    align-items: baseline
}
.\!items-baseline {

    align-items: baseline !important
}
.justify-start {

    justify-content: flex-start
}
.justify-end {

    justify-content: flex-end
}
.justify-center {

    justify-content: center
}
.justify-between {

    justify-content: space-between
}
.justify-around {

    justify-content: space-around
}
.justify-evenly {

    justify-content: space-evenly
}
.justify-items-start {

    justify-items: start
}
.gap-2 {

    gap: 0.5rem
}
.gap-4 {

    gap: 1rem
}
.gap-5 {

    gap: 1.25rem
}
.gap-8 {

    gap: 2rem
}
.gap-0\.5 {

    gap: 0.125rem
}
.gap-0 {

    gap: 0px
}
.gap-3 {

    gap: 0.75rem
}
.gap-1 {

    gap: 0.25rem
}
.gap-6 {

    gap: 1.5rem
}
.gap-3\.5 {

    gap: 0.875rem
}
.gap-2\.5 {

    gap: 0.625rem
}
.gap-\[40px\] {

    gap: 40px
}
.gap-1\.5 {

    gap: 0.375rem
}
.gap-\[1px\] {

    gap: 1px
}
.gap-12 {

    gap: 3rem
}
.\!gap-0\.5 {

    gap: 0.125rem !important
}
.\!gap-0 {

    gap: 0px !important
}
.gap-10 {

    gap: 2.5rem
}
.gap-x-2 {

    column-gap: 0.5rem
}
.gap-y-1 {

    row-gap: 0.25rem
}
.gap-y-2 {

    row-gap: 0.5rem
}
.self-start {

    align-self: flex-start
}
.self-center {

    align-self: center
}
.overflow-auto {

    overflow: auto
}
.overflow-hidden {

    overflow: hidden
}
.overflow-visible {

    overflow: visible
}
.overflow-x-auto {

    overflow-x: auto
}
.overflow-y-auto {

    overflow-y: auto
}
.overflow-x-hidden {

    overflow-x: hidden
}
.overflow-ellipsis {

    text-overflow: ellipsis
}
.text-ellipsis {

    text-overflow: ellipsis
}
.whitespace-normal {

    white-space: normal
}
.whitespace-nowrap {

    white-space: nowrap
}
.whitespace-pre-line {

    white-space: pre-line
}
.whitespace-pre-wrap {

    white-space: pre-wrap
}
.break-words {

    overflow-wrap: break-word
}
.break-all {

    word-break: break-all
}
.rounded-full {

    border-radius: 9999px
}
.rounded-\[6px\] {

    border-radius: 6px
}
.rounded-\[16px\] {

    border-radius: 16px
}
.rounded-lg {

    border-radius: 0.5rem
}
.rounded {

    border-radius: 0.25rem
}
.rounded-sm {

    border-radius: 0.125rem
}
.rounded-2xl {

    border-radius: 1rem
}
.\!rounded-none {

    border-radius: 0px !important
}
.rounded-xl {

    border-radius: 0.75rem
}
.\!rounded-\[16px\] {

    border-radius: 16px !important
}
.\!rounded-lg {

    border-radius: 0.5rem !important
}
.rounded-\[10px\] {

    border-radius: 10px
}
.rounded-\[8px\] {

    border-radius: 8px
}
.rounded-\[18px\] {

    border-radius: 18px
}
.rounded-\[9px\] {

    border-radius: 9px
}
.rounded-\[14px\] {

    border-radius: 14px
}
.\!rounded-\[24px\] {

    border-radius: 24px !important
}
.rounded-none {

    border-radius: 0px
}
.\!rounded-sm {

    border-radius: 0.125rem !important
}
.\!rounded-full {

    border-radius: 9999px !important
}
.rounded-t-none {

    border-top-left-radius: 0px;

    border-top-right-radius: 0px
}
.rounded-b-none {

    border-bottom-right-radius: 0px;

    border-bottom-left-radius: 0px
}
.\!rounded-r-\[6px\] {

    border-top-right-radius: 6px !important;

    border-bottom-right-radius: 6px !important
}
.rounded-t-xl {

    border-top-left-radius: 0.75rem;

    border-top-right-radius: 0.75rem
}
.rounded-b-lg {

    border-bottom-right-radius: 0.5rem;

    border-bottom-left-radius: 0.5rem
}
.\!rounded-t-lg {

    border-top-left-radius: 0.5rem !important;

    border-top-right-radius: 0.5rem !important
}
.\!rounded-b-lg {

    border-bottom-right-radius: 0.5rem !important;

    border-bottom-left-radius: 0.5rem !important
}
.rounded-t-\[14px\] {

    border-top-left-radius: 14px;

    border-top-right-radius: 14px
}
.\!rounded-b-none {

    border-bottom-right-radius: 0px !important;

    border-bottom-left-radius: 0px !important
}
.rounded-r-\[14px\] {

    border-top-right-radius: 14px;

    border-bottom-right-radius: 14px
}
.rounded-l-\[14px\] {

    border-top-left-radius: 14px;

    border-bottom-left-radius: 14px
}
.rounded-tl-xl {

    border-top-left-radius: 0.75rem
}
.rounded-tr-xl {

    border-top-right-radius: 0.75rem
}
.\!rounded-tl-\[0\] {

    border-top-left-radius: 0 !important
}
.border {

    border-width: 1px
}
.border-\[0\.5px\] {

    border-width: 0.5px
}
.border-4 {

    border-width: 4px
}
.border-\[0\.12em\] {

    border-width: 0.12em
}
.border-\[3px\] {

    border-width: 3px
}
.border-solid {

    border-style: solid
}
.border-none {

    border-style: none
}
.border-warmGray-50 {

    --tw-border-opacity: 1;

    border-color: rgb(250 250 249 / var(--tw-border-opacity))
}
.border-\[var\(--accent\)\] {

    border-color: var(--accent)
}
.border-b-transparent {

    border-bottom-color: transparent
}
.bg-amber-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(245 158 11 / var(--tw-bg-opacity))
}
.bg-blueGray-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(100 116 139 / var(--tw-bg-opacity))
}
.bg-red-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}
.bg-black {

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}
.\!bg-transparent {

    background-color: transparent !important
}
.bg-white {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.bg-transparent {

    background-color: transparent
}
.bg-\[var\(--gray\\_smoked\)\] {

    background-color: var(--gray_smoked)
}
.bg-green-400 {

    --tw-bg-opacity: 1;

    background-color: rgb(52 211 153 / var(--tw-bg-opacity))
}
.bg-green-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(16 185 129 / var(--tw-bg-opacity))
}
.bg-\[\#141414\] {

    --tw-bg-opacity: 1;

    background-color: rgb(20 20 20 / var(--tw-bg-opacity))
}
.\!bg-white {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}
.bg-gray-100 {

    --tw-bg-opacity: 1;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.\!bg-black {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important
}
.bg-\[\#0000002e\] {

    background-color: #0000002e
}
.bg-gray-50 {

    --tw-bg-opacity: 1;

    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}
.bg-\[\#52bd5b63\] {

    background-color: #52bd5b63
}
.\!bg-warmGray-50 {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(250 250 249 / var(--tw-bg-opacity)) !important
}
.bg-\[\#ffffff25\] {

    background-color: #ffffff25
}
.bg-opacity-25 {

    --tw-bg-opacity: 0.25
}
.bg-opacity-50 {

    --tw-bg-opacity: 0.5
}
.bg-gradient-to-br {

    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}
.bg-gradient-to-tl {

    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
}
.bg-gradient-to-r {

    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}
.\!bg-gradient-to-br {

    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important
}
.bg-gradient-to-b {

    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}
.bg-gradient-to-bl {

    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
}
.\!bg-gradient-to-b {

    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important
}
.from-red-500 {

    --tw-gradient-from: #ef4444;

    --tw-gradient-to: rgb(239 68 68 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-purple-500 {

    --tw-gradient-from: #8b5cf6;

    --tw-gradient-to: rgb(139 92 246 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-fuchsia-500 {

    --tw-gradient-from: #d946ef;

    --tw-gradient-to: rgb(217 70 239 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-indigo-700 {

    --tw-gradient-from: #4338ca;

    --tw-gradient-to: rgb(67 56 202 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-amber-600 {

    --tw-gradient-from: #d97706;

    --tw-gradient-to: rgb(217 119 6 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-blue-600 {

    --tw-gradient-from: #2563eb;

    --tw-gradient-to: rgb(37 99 235 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-lime-600 {

    --tw-gradient-from: #65a30d;

    --tw-gradient-to: rgb(101 163 13 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-sky-500 {

    --tw-gradient-from: #0ea5e9;

    --tw-gradient-to: rgb(14 165 233 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-teal-500 {

    --tw-gradient-from: #14b8a6;

    --tw-gradient-to: rgb(20 184 166 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-gray-600 {

    --tw-gradient-from: #4b5563;

    --tw-gradient-to: rgb(75 85 99 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-orange-600 {

    --tw-gradient-from: #ea580c;

    --tw-gradient-to: rgb(234 88 12 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-indigo-600 {

    --tw-gradient-from: #4f46e5;

    --tw-gradient-to: rgb(79 70 229 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-red-900 {

    --tw-gradient-from: #7f1d1d;

    --tw-gradient-to: rgb(127 29 29 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-red-700 {

    --tw-gradient-from: #b91c1c;

    --tw-gradient-to: rgb(185 28 28 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-blue-400 {

    --tw-gradient-from: #60a5fa;

    --tw-gradient-to: rgb(96 165 250 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-gray-500 {

    --tw-gradient-from: #6b7280;

    --tw-gradient-to: rgb(107 114 128 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-green-500 {

    --tw-gradient-from: #10b981;

    --tw-gradient-to: rgb(16 185 129 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-\[\#0077ff10\] {

    --tw-gradient-from: #0077ff10;

    --tw-gradient-to: rgb(0 119 255 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-white {

    --tw-gradient-from: #fff;

    --tw-gradient-to: rgb(255 255 255 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-green-700 {

    --tw-gradient-from: #047857;

    --tw-gradient-to: rgb(4 120 87 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-green-600 {

    --tw-gradient-from: #059669;

    --tw-gradient-to: rgb(5 150 105 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-gray-200 {

    --tw-gradient-from: #e5e7eb;

    --tw-gradient-to: rgb(229 231 235 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-gray-100 {

    --tw-gradient-from: #f3f4f6;

    --tw-gradient-to: rgb(243 244 246 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-gray-300 {

    --tw-gradient-from: #d1d5db;

    --tw-gradient-to: rgb(209 213 219 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-yellow-400 {

    --tw-gradient-from: #fbbf24;

    --tw-gradient-to: rgb(251 191 36 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-orange-300 {

    --tw-gradient-from: #fdba74;

    --tw-gradient-to: rgb(253 186 116 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-amber-300 {

    --tw-gradient-from: #fcd34d;

    --tw-gradient-to: rgb(252 211 77 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-transparent {

    --tw-gradient-from: transparent;

    --tw-gradient-to: rgb(0 0 0 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-purple-600 {

    --tw-gradient-from: #7c3aed;

    --tw-gradient-to: rgb(124 58 237 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-green-400 {

    --tw-gradient-from: #34d399;

    --tw-gradient-to: rgb(52 211 153 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-yellow-300 {

    --tw-gradient-from: #fcd34d;

    --tw-gradient-to: rgb(252 211 77 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.via-blue-300 {

    --tw-gradient-to: rgb(147 197 253 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to)
}
.via-orange-500 {

    --tw-gradient-to: rgb(249 115 22 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), #f97316, var(--tw-gradient-to)
}
.via-blue-100 {

    --tw-gradient-to: rgb(219 234 254 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to)
}
.to-yellow-500 {

    --tw-gradient-to: #f59e0b
}
.to-blue-500 {

    --tw-gradient-to: #3b82f6
}
.to-purple-500 {

    --tw-gradient-to: #8b5cf6
}
.to-indigo-500 {

    --tw-gradient-to: #6366f1
}
.to-red-400 {

    --tw-gradient-to: #f87171
}
.to-blue-400 {

    --tw-gradient-to: #60a5fa
}
.to-green-400 {

    --tw-gradient-to: #34d399
}
.to-sky-400 {

    --tw-gradient-to: #38bdf8
}
.to-yellow-400 {

    --tw-gradient-to: #fbbf24
}
.to-orange-400 {

    --tw-gradient-to: #fb923c
}
.to-red-700 {

    --tw-gradient-to: #b91c1c
}
.to-red-600 {

    --tw-gradient-to: #dc2626
}
.to-orange-700 {

    --tw-gradient-to: #c2410c
}
.to-blue-700 {

    --tw-gradient-to: #1d4ed8
}
.to-gray-400 {

    --tw-gradient-to: #9ca3af
}
.to-lime-600 {

    --tw-gradient-to: #65a30d
}
.to-\[\#0077ff25\] {

    --tw-gradient-to: #0077ff25
}
.to-white {

    --tw-gradient-to: #fff
}
.to-lime-500 {

    --tw-gradient-to: #84cc16
}
.to-transparent {

    --tw-gradient-to: transparent
}
.to-gray-500 {

    --tw-gradient-to: #6b7280
}
.to-orange-500 {

    --tw-gradient-to: #f97316
}
.to-gray-100 {

    --tw-gradient-to: #f3f4f6
}
.to-green-600 {

    --tw-gradient-to: #059669
}
.to-green-700 {

    --tw-gradient-to: #047857
}
.\!to-transparent {

    --tw-gradient-to: transparent !important
}
.to-gray-300 {

    --tw-gradient-to: #d1d5db
}
.to-amber-500 {

    --tw-gradient-to: #f59e0b
}
.bg-cover {

    background-size: cover
}
.bg-clip-text {

    -webkit-background-clip: text;

            background-clip: text
}
.bg-center {

    background-position: center
}
.bg-no-repeat {

    background-repeat: no-repeat
}
.object-contain {

    object-fit: contain
}
.object-cover {

    object-fit: cover
}
.p-2 {

    padding: 0.5rem
}
.p-10 {

    padding: 2.5rem
}
.p-4 {

    padding: 1rem
}
.p-5 {

    padding: 1.25rem
}
.\!p-0 {

    padding: 0px !important
}
.p-0\.5 {

    padding: 0.125rem
}
.p-0 {

    padding: 0px
}
.p-1 {

    padding: 0.25rem
}
.p-3 {

    padding: 0.75rem
}
.p-1\.5 {

    padding: 0.375rem
}
.p-6 {

    padding: 1.5rem
}
.\!p-2 {

    padding: 0.5rem !important
}
.p-\[2px\] {

    padding: 2px
}
.p-\[3px\] {

    padding: 3px
}
.\!p-1\.5 {

    padding: 0.375rem !important
}
.\!p-1 {

    padding: 0.25rem !important
}
.p-2\.5 {

    padding: 0.625rem
}
.\!p-0\.5 {

    padding: 0.125rem !important
}
.p-8 {

    padding: 2rem
}
.p-7 {

    padding: 1.75rem
}
.px-6 {

    padding-left: 1.5rem;

    padding-right: 1.5rem
}
.px-4 {

    padding-left: 1rem;

    padding-right: 1rem
}
.py-0 {

    padding-top: 0px;

    padding-bottom: 0px
}
.\!px-3 {

    padding-left: 0.75rem !important;

    padding-right: 0.75rem !important
}
.\!px-5 {

    padding-left: 1.25rem !important;

    padding-right: 1.25rem !important
}
.px-0 {

    padding-left: 0px;

    padding-right: 0px
}
.py-2 {

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}
.px-0\.5 {

    padding-left: 0.125rem;

    padding-right: 0.125rem
}
.py-1 {

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}
.py-3 {

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}
.px-2\.5 {

    padding-left: 0.625rem;

    padding-right: 0.625rem
}
.px-2 {

    padding-left: 0.5rem;

    padding-right: 0.5rem
}
.px-3 {

    padding-left: 0.75rem;

    padding-right: 0.75rem
}
.px-5 {

    padding-left: 1.25rem;

    padding-right: 1.25rem
}
.py-0\.5 {

    padding-top: 0.125rem;

    padding-bottom: 0.125rem
}
.px-1\.5 {

    padding-left: 0.375rem;

    padding-right: 0.375rem
}
.px-1 {

    padding-left: 0.25rem;

    padding-right: 0.25rem
}
.py-2\.5 {

    padding-top: 0.625rem;

    padding-bottom: 0.625rem
}
.py-5 {

    padding-top: 1.25rem;

    padding-bottom: 1.25rem
}
.py-12 {

    padding-top: 3rem;

    padding-bottom: 3rem
}
.px-\[var\(--thin-border\)\] {

    padding-left: var(--thin-border);

    padding-right: var(--thin-border)
}
.py-6 {

    padding-top: 1.5rem;

    padding-bottom: 1.5rem
}
.py-1\.5 {

    padding-top: 0.375rem;

    padding-bottom: 0.375rem
}
.\!py-1 {

    padding-top: 0.25rem !important;

    padding-bottom: 0.25rem !important
}
.\!px-2 {

    padding-left: 0.5rem !important;

    padding-right: 0.5rem !important
}
.\!py-0 {

    padding-top: 0px !important;

    padding-bottom: 0px !important
}
.py-\[0\.925rem\] {

    padding-top: 0.925rem;

    padding-bottom: 0.925rem
}
.py-\[10px\] {

    padding-top: 10px;

    padding-bottom: 10px
}
.px-\[3px\] {

    padding-left: 3px;

    padding-right: 3px
}
.px-3\.5 {

    padding-left: 0.875rem;

    padding-right: 0.875rem
}
.py-8 {

    padding-top: 2rem;

    padding-bottom: 2rem
}
.px-8 {

    padding-left: 2rem;

    padding-right: 2rem
}
.py-4 {

    padding-top: 1rem;

    padding-bottom: 1rem
}
.\!py-\[26px\] {

    padding-top: 26px !important;

    padding-bottom: 26px !important
}
.\!px-2\.5 {

    padding-left: 0.625rem !important;

    padding-right: 0.625rem !important
}
.\!py-1\.5 {

    padding-top: 0.375rem !important;

    padding-bottom: 0.375rem !important
}
.pb-1 {

    padding-bottom: 0.25rem
}
.pt-5 {

    padding-top: 1.25rem
}
.pb-0 {

    padding-bottom: 0px
}
.pb-3 {

    padding-bottom: 0.75rem
}
.pl-2 {

    padding-left: 0.5rem
}
.pr-2 {

    padding-right: 0.5rem
}
.\!pt-0 {

    padding-top: 0px !important
}
.\!pb-0 {

    padding-bottom: 0px !important
}
.\!pl-2 {

    padding-left: 0.5rem !important
}
.\!pr-1 {

    padding-right: 0.25rem !important
}
.pr-0\.5 {

    padding-right: 0.125rem
}
.pr-0 {

    padding-right: 0px
}
.pt-6 {

    padding-top: 1.5rem
}
.pb-8 {

    padding-bottom: 2rem
}
.pt-2 {

    padding-top: 0.5rem
}
.pt-1 {

    padding-top: 0.25rem
}
.pt-12 {

    padding-top: 3rem
}
.pb-5 {

    padding-bottom: 1.25rem
}
.pb-4 {

    padding-bottom: 1rem
}
.pt-0\.5 {

    padding-top: 0.125rem
}
.pt-0 {

    padding-top: 0px
}
.pt-8 {

    padding-top: 2rem
}
.pt-4 {

    padding-top: 1rem
}
.pb-0\.5 {

    padding-bottom: 0.125rem
}
.pt-1\.5 {

    padding-top: 0.375rem
}
.pb-2 {

    padding-bottom: 0.5rem
}
.pb-1\.5 {

    padding-bottom: 0.375rem
}
.pt-3 {

    padding-top: 0.75rem
}
.pb-10 {

    padding-bottom: 2.5rem
}
.\!pr-4 {

    padding-right: 1rem !important
}
.\!pb-0\.5 {

    padding-bottom: 0.125rem !important
}
.pl-4 {

    padding-left: 1rem
}
.pl-3 {

    padding-left: 0.75rem
}
.pt-3\.5 {

    padding-top: 0.875rem
}
.pt-14 {

    padding-top: 3.5rem
}
.pb-16 {

    padding-bottom: 4rem
}
.\!pr-0\.5 {

    padding-right: 0.125rem !important
}
.\!pr-0 {

    padding-right: 0px !important
}
.pl-8 {

    padding-left: 2rem
}
.pt-10 {

    padding-top: 2.5rem
}
.pb-2\.5 {

    padding-bottom: 0.625rem
}
.pr-\[30px\] {

    padding-right: 30px
}
.pr-px {

    padding-right: 1px
}
.pr-\[18px\] {

    padding-right: 18px
}
.pl-\[2px\] {

    padding-left: 2px
}
.pb-6 {

    padding-bottom: 1.5rem
}
.pr-4 {

    padding-right: 1rem
}
.pr-3 {

    padding-right: 0.75rem
}
.pl-1 {

    padding-left: 0.25rem
}
.pr-10 {

    padding-right: 2.5rem
}
.pt-2\.5 {

    padding-top: 0.625rem
}
.pl-\[calc\(0\.25rem_\+_12px\)\] {

    padding-left: calc(0.25rem + 12px)
}
.pb-\[3\.25rem\] {

    padding-bottom: 3.25rem
}
.pt-\[1\.5px\] {

    padding-top: 1.5px
}
.pr-\[2px\] {

    padding-right: 2px
}
.pl-0 {

    padding-left: 0px
}
.pr-\[56px\] {

    padding-right: 56px
}
.\!pl-1\.5 {

    padding-left: 0.375rem !important
}
.\!pr-2 {

    padding-right: 0.5rem !important
}
.\!pl-1 {

    padding-left: 0.25rem !important
}
.pr-\[6\.5rem\] {

    padding-right: 6.5rem
}
.pb-\[1px\] {

    padding-bottom: 1px
}
.pr-1 {

    padding-right: 0.25rem
}
.pr-1\.5 {

    padding-right: 0.375rem
}
.pl-2\.5 {

    padding-left: 0.625rem
}
.pb-7 {

    padding-bottom: 1.75rem
}
.pl-5 {

    padding-left: 1.25rem
}
.text-left {

    text-align: left
}
.text-center {

    text-align: center
}
.text-right {

    text-align: right
}
.align-middle {

    vertical-align: middle
}
.\!text-lg {

    font-size: 1.125rem !important;

    line-height: 1.75rem !important
}
.text-5xl {

    font-size: 3rem;

    line-height: 1
}
.text-sm {

    font-size: 0.875rem;

    line-height: 1.25rem
}
.text-lg {

    font-size: 1.125rem;

    line-height: 1.75rem
}
.text-xs {

    font-size: 0.75rem;

    line-height: 1rem
}
.text-2xl {

    font-size: 1.5rem;

    line-height: 2rem
}
.text-3xl {

    font-size: 1.875rem;

    line-height: 2.25rem
}
.text-xl {

    font-size: 1.25rem;

    line-height: 1.75rem
}
.text-base {

    font-size: 1rem;

    line-height: 1.5rem
}
.text-7xl {

    font-size: 4.5rem;

    line-height: 1
}
.font-semibold {

    font-weight: 600
}
.font-medium {

    font-weight: 500
}
.font-bold {

    font-weight: 700
}
.font-normal {

    font-weight: 400
}
.uppercase {

    text-transform: uppercase
}
.capitalize {

    text-transform: capitalize
}
.italic {

    font-style: italic
}
.ordinal {

    --tw-ordinal: ordinal;

    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}
.leading-\[20px\] {

    line-height: 20px
}
.leading-\[80px\] {

    line-height: 80px
}
.leading-\[40px\] {

    line-height: 40px
}
.leading-none {

    line-height: 1
}
.leading-3 {

    line-height: .75rem
}
.leading-4 {

    line-height: 1rem
}
.text-green-400 {

    --tw-text-opacity: 1;

    color: rgb(52 211 153 / var(--tw-text-opacity))
}
.text-green-600 {

    --tw-text-opacity: 1;

    color: rgb(5 150 105 / var(--tw-text-opacity))
}
.text-red-400 {

    --tw-text-opacity: 1;

    color: rgb(248 113 113 / var(--tw-text-opacity))
}
.text-red-600 {

    --tw-text-opacity: 1;

    color: rgb(220 38 38 / var(--tw-text-opacity))
}
.text-white {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.\!text-black {

    --tw-text-opacity: 1 !important;

    color: rgb(0 0 0 / var(--tw-text-opacity)) !important
}
.text-\[var\(--rate\\_gold\)\] {

    color: var(--rate_gold)
}
.text-orange-700 {

    --tw-text-opacity: 1;

    color: rgb(194 65 12 / var(--tw-text-opacity))
}
.text-red-500 {

    --tw-text-opacity: 1;

    color: rgb(239 68 68 / var(--tw-text-opacity))
}
.text-black {

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.text-orange-600 {

    --tw-text-opacity: 1;

    color: rgb(234 88 12 / var(--tw-text-opacity))
}
.text-yellow-300 {

    --tw-text-opacity: 1;

    color: rgb(252 211 77 / var(--tw-text-opacity))
}
.text-green-500 {

    --tw-text-opacity: 1;

    color: rgb(16 185 129 / var(--tw-text-opacity))
}
.text-gray-500 {

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.text-opacity-80 {

    --tw-text-opacity: 0.8
}
.text-opacity-70 {

    --tw-text-opacity: 0.7
}
.text-opacity-60 {

    --tw-text-opacity: 0.6
}
.underline {

    text-decoration-line: underline
}
.line-through {

    text-decoration-line: line-through
}
.no-underline {

    text-decoration-line: none
}
.opacity-50 {

    opacity: 0.5
}
.opacity-0 {

    opacity: 0
}
.opacity-20 {

    opacity: 0.2
}
.opacity-40 {

    opacity: 0.4
}
.opacity-75 {

    opacity: 0.75
}
.opacity-70 {

    opacity: 0.7
}
.opacity-100 {

    opacity: 1
}
.\!opacity-20 {

    opacity: 0.2 !important
}
.opacity-\[0\.85\] {

    opacity: 0.85
}
.\!opacity-0 {

    opacity: 0 !important
}
.opacity-25 {

    opacity: 0.25
}
.opacity-60 {

    opacity: 0.6
}
.opacity-80 {

    opacity: 0.8
}
.shadow {

    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-sm {

    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-lg {

    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-2xl {

    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-md {

    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-inner {

    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);

    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-none {

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.\!shadow-none {

    --tw-shadow: 0 0 #0000 !important;

    --tw-shadow-colored: 0 0 #0000 !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}
.shadow-xl {

    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 10px 10px -5px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.\!outline-none {

    outline: 2px solid transparent !important;

    outline-offset: 2px !important
}
.outline {

    outline-style: solid
}
.blur {

    --tw-blur: blur(8px);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.invert {

    --tw-invert: invert(100%);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.\!invert {

    --tw-invert: invert(100%) !important;

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}
.filter {

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.\!filter {

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}
.backdrop-filter {

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}
.transition {

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}
.transition-opacity {

    transition-property: opacity;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}
.transition-all {

    transition-property: all;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}
.duration-500 {

    transition-duration: 500ms
}
.duration-300 {

    transition-duration: 300ms
}
.duration-150 {

    transition-duration: 150ms
}
.ease-in-out {

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.line-clamp-1 {

    overflow: hidden;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 1
}
.line-clamp-6 {

    overflow: hidden;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 6
}
.line-clamp-2 {

    overflow: hidden;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2
}
.line-clamp-none {

    -webkit-line-clamp: unset
}
.first-letter\:capitalize::first-letter {

    text-transform: capitalize
}
.before\:content-none::before {

    --tw-content: none;

    content: var(--tw-content)
}
.first\:mt-2:first-child {

    margin-top: 0.5rem
}
.first\:-mt-2:first-child {

    margin-top: -0.5rem
}
.first\:ml-\[10px\]:first-child {

    margin-left: 10px
}
.first\:ml-2:first-child {

    margin-left: 0.5rem
}
.first\:mt-auto:first-child {

    margin-top: auto
}
.first\:ml-4:first-child {

    margin-left: 1rem
}
.first\:mb-9:first-child {

    margin-bottom: 2.25rem
}
.first\:\!mb-6:first-child {

    margin-bottom: 1.5rem !important
}
.first\:ml-0:first-child {

    margin-left: 0px
}
.first\:\!rounded-t-\[14px\]:first-child {

    border-top-left-radius: 14px !important;

    border-top-right-radius: 14px !important
}
.first\:rounded-l-lg:first-child {

    border-top-left-radius: 0.5rem;

    border-bottom-left-radius: 0.5rem
}
.first\:pt-2:first-child {

    padding-top: 0.5rem
}
.last\:-mb-2:last-child {

    margin-bottom: -0.5rem
}
.last\:mb-0:last-child {

    margin-bottom: 0px
}
.last\:mb-2:last-child {

    margin-bottom: 0.5rem
}
.last\:mb-6:last-child {

    margin-bottom: 1.5rem
}
.last\:mr-\[6px\]:last-child {

    margin-right: 6px
}
.last\:\!rounded-b-lg:last-child {

    border-bottom-right-radius: 0.5rem !important;

    border-bottom-left-radius: 0.5rem !important
}
.last\:rounded-b-lg:last-child {

    border-bottom-right-radius: 0.5rem;

    border-bottom-left-radius: 0.5rem
}
.last\:rounded-r-lg:last-child {

    border-top-right-radius: 0.5rem;

    border-bottom-right-radius: 0.5rem
}
.last\:\!rounded-b-\[14px\]:last-child {

    border-bottom-right-radius: 14px !important;

    border-bottom-left-radius: 14px !important
}
.last\:\!border-none:last-child {

    border-style: none !important
}
.hover\:opacity-70:hover {

    opacity: 0.7
}
.hover\:opacity-100:hover {

    opacity: 1
}
.hover\:opacity-90:hover {

    opacity: 0.9
}
.hover\:opacity-75:hover {

    opacity: 0.75
}
.active\:opacity-70:active {

    opacity: 0.7
}
.group:hover .group-hover\:visible {

    visibility: visible
}
.group:hover .group-hover\:flex {

    display: flex
}
.group:hover .group-hover\:\!text-\[var\(--accent\)\] {

    color: var(--accent) !important
}
.group:hover .group-hover\:opacity-100 {

    opacity: 1
}
.dark .dark\:border-transparent {

    border-color: transparent
}
.dark .dark\:bg-lime-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(132 204 22 / var(--tw-bg-opacity))
}
.dark .dark\:bg-lime-600 {

    --tw-bg-opacity: 1;

    background-color: rgb(101 163 13 / var(--tw-bg-opacity))
}
.dark .dark\:bg-white {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.dark .dark\:\!bg-\[var\(--placeholder\\_icon\\_background\)\] {

    background-color: var(--placeholder_icon_background) !important
}
.dark .dark\:\!bg-\[var\(--placeholder\\\\_icon\\\\_background\)\] {

    background-color: var(--placeholder\_icon\_background) !important
}
.dark .dark\:bg-blueGray-700 {

    --tw-bg-opacity: 1;

    background-color: rgb(51 65 85 / var(--tw-bg-opacity))
}
.dark .dark\:bg-transparent {

    background-color: transparent
}
.dark .dark\:from-blue-400 {

    --tw-gradient-from: #60a5fa;

    --tw-gradient-to: rgb(96 165 250 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.dark .dark\:from-gray-600 {

    --tw-gradient-from: #4b5563;

    --tw-gradient-to: rgb(75 85 99 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.dark .dark\:from-green-600 {

    --tw-gradient-from: #059669;

    --tw-gradient-to: rgb(5 150 105 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.dark .dark\:from-transparent {

    --tw-gradient-from: transparent;

    --tw-gradient-to: rgb(0 0 0 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}
.dark .dark\:via-gray-800 {

    --tw-gradient-to: rgb(31 41 55 / 0);

    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to)
}
.dark .dark\:to-purple-500 {

    --tw-gradient-to: #8b5cf6
}
.dark .dark\:to-gray-500 {

    --tw-gradient-to: #6b7280
}
.dark .dark\:to-lime-600 {

    --tw-gradient-to: #65a30d
}
.dark .dark\:to-gray-900 {

    --tw-gradient-to: #111827
}
.dark .dark\:text-black {

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.dark .dark\:text-yellow-500 {

    --tw-text-opacity: 1;

    color: rgb(245 158 11 / var(--tw-text-opacity))
}
.dark .dark\:\!text-white {

    --tw-text-opacity: 1 !important;

    color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}
.dark .dark\:opacity-75 {

    opacity: 0.75
}
@media (min-width: 350px) and (max-width: 388px) {

    .xs\:relative {

        position: relative
    }

    .xs\:hidden {

        display: none
    }

    .xs\:\!max-h-\[50px\] {

        max-height: 50px !important
    }

    .xs\:\!max-w-\[50px\] {

        max-width: 50px !important
    }
}
@media (min-width: 768px) and (max-width: 1023px) {

    .md\:\!w-\[255px\] {

        width: 255px !important
    }

    .md\:\!min-w-\[255px\] {

        min-width: 255px !important
    }

    .md\:\!max-w-\[300px\] {

        max-width: 300px !important
    }

    .md\:flex-col {

        flex-direction: column
    }

    .md\:items-center {

        align-items: center
    }
}
@media (min-width: 1024px) and (max-width: 1279px) {

    .lg\:flex-col {

        flex-direction: column
    }
}
@media (min-width: 1024px) and (max-width: 1315px) {

    .lgl\:\!w-\[320px\] {

        width: 320px !important
    }

    .lgl\:\!min-w-\[320px\] {

        min-width: 320px !important
    }
}
@media (max-width: 767px) {

    .m\:fixed {

        position: fixed
    }

    .m\:sticky {

        position: sticky
    }

    .m\:bottom-5 {

        bottom: 1.25rem
    }

    .m\:\!bottom-\[70px\] {

        bottom: 70px !important
    }

    .m\:left-\[-4px\] {

        left: -4px
    }

    .m\:top-7 {

        top: 1.75rem
    }

    .m\:bottom-\[55px\] {

        bottom: 55px
    }

    .m\:z-10 {

        z-index: 10
    }

    .m\:\!z-10 {

        z-index: 10 !important
    }

    .m\:m-3 {

        margin: 0.75rem
    }

    .m\:m-4 {

        margin: 1rem
    }

    .m\:\!m-0 {

        margin: 0px !important
    }

    .m\:-mx-4 {

        margin-left: -1rem;

        margin-right: -1rem
    }

    .m\:mx-1 {

        margin-left: 0.25rem;

        margin-right: 0.25rem
    }

    .m\:mx-4 {

        margin-left: 1rem;

        margin-right: 1rem
    }

    .m\:mx-3 {

        margin-left: 0.75rem;

        margin-right: 0.75rem
    }

    .m\:mx-2 {

        margin-left: 0.5rem;

        margin-right: 0.5rem
    }

    .m\:my-2 {

        margin-top: 0.5rem;

        margin-bottom: 0.5rem
    }

    .m\:my-8 {

        margin-top: 2rem;

        margin-bottom: 2rem
    }

    .m\:mb-0 {

        margin-bottom: 0px
    }

    .m\:mt-auto {

        margin-top: auto
    }

    .m\:mt-6 {

        margin-top: 1.5rem
    }

    .m\:mt-2 {

        margin-top: 0.5rem
    }

    .m\:mb-2 {

        margin-bottom: 0.5rem
    }

    .m\:mt-0 {

        margin-top: 0px
    }

    .m\:mt-4 {

        margin-top: 1rem
    }

    .m\:mt-0\.5 {

        margin-top: 0.125rem
    }

    .m\:mb-3 {

        margin-bottom: 0.75rem
    }

    .m\:mb-4 {

        margin-bottom: 1rem
    }

    .m\:mb-7 {

        margin-bottom: 1.75rem
    }

    .m\:mb-1 {

        margin-bottom: 0.25rem
    }

    .m\:mb-\[2px\] {

        margin-bottom: 2px
    }

    .m\:mr-1 {

        margin-right: 0.25rem
    }

    .m\:mb-14 {

        margin-bottom: 3.5rem
    }

    .m\:mt-3 {

        margin-top: 0.75rem
    }

    .m\:\!mt-\[-10px\] {

        margin-top: -10px !important
    }

    .m\:\!mt-4 {

        margin-top: 1rem !important
    }

    .m\:mt-1 {

        margin-top: 0.25rem
    }

    .m\:mb-0\.5 {

        margin-bottom: 0.125rem
    }

    .m\:ml-0 {

        margin-left: 0px
    }

    .m\:flex {

        display: flex
    }

    .m\:hidden {

        display: none
    }

    .m\:h-3 {

        height: 0.75rem
    }

    .m\:h-\[44px\] {

        height: 44px
    }

    .m\:h-\[100px\] {

        height: 100px
    }

    .m\:\!h-\[15\.5px\] {

        height: 15.5px !important
    }

    .m\:h-\[60px\] {

        height: 60px
    }

    .m\:h-\[38px\] {

        height: 38px
    }

    .m\:h-full {

        height: 100%
    }

    .m\:\!h-2 {

        height: 0.5rem !important
    }

    .m\:h-\[200px\] {

        height: 200px
    }

    .m\:min-h-\[168px\] {

        min-height: 168px
    }

    .m\:w-full {

        width: 100%
    }

    .m\:\!w-\[132px\] {

        width: 132px !important
    }

    .m\:w-\[calc\(100vw_-_2rem\)\] {

        width: calc(100vw - 2rem)
    }

    .m\:w-\[200px\] {

        width: 200px
    }

    .m\:w-\[calc\(100\%_-_40px\)\] {

        width: calc(100% - 40px)
    }

    .m\:max-w-\[160px\] {

        max-width: 160px
    }

    .m\:max-w-\[260px\] {

        max-width: 260px
    }

    .m\:flex-1 {

        flex: 1 1 0%
    }

    .m\:cursor-pointer {

        cursor: pointer
    }

    .m\:grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .m\:grid-cols-\[minmax\(0\2c _0\.77fr\)_minmax\(0\2c _1fr\)_minmax\(0\2c _0\.88fr\)\] {

        grid-template-columns: minmax(0, 0.77fr) minmax(0, 1fr) minmax(0, 0.88fr)
    }

    .m\:flex-col {

        flex-direction: column
    }

    .m\:items-start {

        align-items: flex-start
    }

    .m\:items-center {

        align-items: center
    }

    .m\:justify-center {

        justify-content: center
    }

    .m\:gap-4 {

        gap: 1rem
    }

    .m\:gap-3 {

        gap: 0.75rem
    }

    .m\:gap-8 {

        gap: 2rem
    }

    .m\:self-start {

        align-self: flex-start
    }

    .m\:overflow-visible {

        overflow: visible
    }

    .m\:\!rounded-2xl {

        border-radius: 1rem !important
    }

    .m\:rounded-none {

        border-radius: 0px
    }

    .m\:rounded-full {

        border-radius: 9999px
    }

    .m\:\!rounded-none {

        border-radius: 0px !important
    }

    .m\:bg-gradient-to-t {

        background-image: linear-gradient(to top, var(--tw-gradient-stops))
    }

    .m\:bg-gradient-to-b {

        background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
    }

    .m\:bg-left-top {

        background-position: left top
    }

    .m\:bg-center {

        background-position: center
    }

    .m\:p-4 {

        padding: 1rem
    }

    .m\:\!p-0 {

        padding: 0px !important
    }

    .m\:px-3 {

        padding-left: 0.75rem;

        padding-right: 0.75rem
    }

    .m\:py-4 {

        padding-top: 1rem;

        padding-bottom: 1rem
    }

    .m\:px-4 {

        padding-left: 1rem;

        padding-right: 1rem
    }

    .m\:\!py-8 {

        padding-top: 2rem !important;

        padding-bottom: 2rem !important
    }

    .m\:px-7 {

        padding-left: 1.75rem;

        padding-right: 1.75rem
    }

    .m\:py-2 {

        padding-top: 0.5rem;

        padding-bottom: 0.5rem
    }

    .m\:px-2 {

        padding-left: 0.5rem;

        padding-right: 0.5rem
    }

    .m\:\!px-0 {

        padding-left: 0px !important;

        padding-right: 0px !important
    }

    .m\:px-\[1rem\] {

        padding-left: 1rem;

        padding-right: 1rem
    }

    .m\:py-0 {

        padding-top: 0px;

        padding-bottom: 0px
    }

    .m\:px-1 {

        padding-left: 0.25rem;

        padding-right: 0.25rem
    }

    .m\:py-1 {

        padding-top: 0.25rem;

        padding-bottom: 0.25rem
    }

    .m\:pt-7 {

        padding-top: 1.75rem
    }

    .m\:pb-0 {

        padding-bottom: 0px
    }

    .m\:pt-0 {

        padding-top: 0px
    }

    .m\:pt-2 {

        padding-top: 0.5rem
    }

    .m\:pt-1\.5 {

        padding-top: 0.375rem
    }

    .m\:pt-1 {

        padding-top: 0.25rem
    }

    .m\:pb-1 {

        padding-bottom: 0.25rem
    }

    .m\:pb-16 {

        padding-bottom: 4rem
    }

    .m\:pb-\[56px\] {

        padding-bottom: 56px
    }

    .m\:pb-8 {

        padding-bottom: 2rem
    }

    .m\:pl-0\.5 {

        padding-left: 0.125rem
    }

    .m\:pl-0 {

        padding-left: 0px
    }

    .m\:pt-2\.5 {

        padding-top: 0.625rem
    }

    .m\:pb-2 {

        padding-bottom: 0.5rem
    }

    .m\:pb-5 {

        padding-bottom: 1.25rem
    }

    .m\:pt-4 {

        padding-top: 1rem
    }

    .m\:text-center {

        text-align: center
    }

    .first\:m\:mt-2:first-child {

        margin-top: 0.5rem
    }
}
@media (min-width: 768px) {

    .d\:sticky {

        position: sticky
    }

    .d\:\!sticky {

        position: sticky !important
    }

    .d\:left-9 {

        left: 2.25rem
    }

    .d\:bottom-\[-270px\] {

        bottom: -270px
    }

    .d\:top-0 {

        top: 0px
    }

    .d\:mx-auto {

        margin-left: auto;

        margin-right: auto
    }

    .d\:mx-24 {

        margin-left: 6rem;

        margin-right: 6rem
    }

    .d\:\!my-0 {

        margin-top: 0px !important;

        margin-bottom: 0px !important
    }

    .d\:\!mx-0 {

        margin-left: 0px !important;

        margin-right: 0px !important
    }

    .d\:-mx-4 {

        margin-left: -1rem;

        margin-right: -1rem
    }

    .d\:mx-3 {

        margin-left: 0.75rem;

        margin-right: 0.75rem
    }

    .d\:my-12 {

        margin-top: 3rem;

        margin-bottom: 3rem
    }

    .d\:my-8 {

        margin-top: 2rem;

        margin-bottom: 2rem
    }

    .d\:my-6 {

        margin-top: 1.5rem;

        margin-bottom: 1.5rem
    }

    .d\:mt-3 {

        margin-top: 0.75rem
    }

    .d\:mt-5 {

        margin-top: 1.25rem
    }

    .d\:mb-3 {

        margin-bottom: 0.75rem
    }

    .d\:mt-4 {

        margin-top: 1rem
    }

    .d\:mb-6 {

        margin-bottom: 1.5rem
    }

    .d\:mb-2 {

        margin-bottom: 0.5rem
    }

    .d\:mt-2 {

        margin-top: 0.5rem
    }

    .d\:mb-1 {

        margin-bottom: 0.25rem
    }

    .d\:mt-8 {

        margin-top: 2rem
    }

    .d\:mt-\[2px\] {

        margin-top: 2px
    }

    .d\:mt-1\.5 {

        margin-top: 0.375rem
    }

    .d\:mt-1 {

        margin-top: 0.25rem
    }

    .d\:mr-3 {

        margin-right: 0.75rem
    }

    .d\:mb-0 {

        margin-bottom: 0px
    }

    .d\:ml-\[70\.5px\] {

        margin-left: 70.5px
    }

    .d\:mr-4 {

        margin-right: 1rem
    }

    .d\:\!mt-\[-4px\] {

        margin-top: -4px !important
    }

    .d\:mt-12 {

        margin-top: 3rem
    }

    .d\:mb-5 {

        margin-bottom: 1.25rem
    }

    .d\:mb-4 {

        margin-bottom: 1rem
    }

    .d\:mt-16 {

        margin-top: 4rem
    }

    .d\:mt-32 {

        margin-top: 8rem
    }

    .d\:mr-2 {

        margin-right: 0.5rem
    }

    .d\:mb-1\.5 {

        margin-bottom: 0.375rem
    }

    .d\:flex {

        display: flex
    }

    .d\:grid {

        display: grid
    }

    .d\:hidden {

        display: none
    }

    .d\:h-\[210px\] {

        height: 210px
    }

    .d\:\!h-\[calc\(100\%_-_52px\)\] {

        height: calc(100% - 52px) !important
    }

    .d\:\!h-\[calc\(100\%_-_54px\)\] {

        height: calc(100% - 54px) !important
    }

    .d\:h-\[calc\(var\(--app-height\)_-_88px\)\] {

        height: calc(var(--app-height) - 88px)
    }

    .d\:h-\[calc\(100\%_-_97px\)\] {

        height: calc(100% - 97px)
    }

    .d\:h-\[calc\(100\%_-_52px\)\] {

        height: calc(100% - 52px)
    }

    .d\:h-\[110px\] {

        height: 110px
    }

    .d\:max-h-\[1000px\] {

        max-height: 1000px
    }

    .d\:max-h-\[200px\] {

        max-height: 200px
    }

    .d\:max-h-\[400px\] {

        max-height: 400px
    }

    .d\:min-h-\[300px\] {

        min-height: 300px
    }

    .d\:min-h-\[450px\] {

        min-height: 450px
    }

    .d\:min-h-\[384px\] {

        min-height: 384px
    }

    .d\:w-\[400px\] {

        width: 400px
    }

    .d\:w-full {

        width: 100%
    }

    .d\:w-\[425px\] {

        width: 425px
    }

    .d\:w-\[380px\] {

        width: 380px
    }

    .d\:w-3\/4 {

        width: 75%
    }

    .d\:w-\[110px\] {

        width: 110px
    }

    .d\:max-w-\[700px\] {

        max-width: 700px
    }

    .d\:\!max-w-\[calc\(100\%_-_2rem\)\] {

        max-width: calc(100% - 2rem) !important
    }

    .d\:max-w-\[320px\] {

        max-width: 320px
    }

    .d\:max-w-\[300px\] {

        max-width: 300px
    }

    .d\:max-w-\[425px\] {

        max-width: 425px
    }

    .d\:grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .d\:grid-cols-3 {

        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .d\:flex-row {

        flex-direction: row
    }

    .d\:flex-col {

        flex-direction: column
    }

    .d\:items-center {

        align-items: center
    }

    .d\:justify-center {

        justify-content: center
    }

    .d\:justify-between {

        justify-content: space-between
    }

    .d\:justify-around {

        justify-content: space-around
    }

    .d\:gap-1 {

        gap: 0.25rem
    }

    .d\:gap-2 {

        gap: 0.5rem
    }

    .d\:gap-8 {

        gap: 2rem
    }

    .d\:overflow-auto {

        overflow: auto
    }

    .d\:rounded-\[24px\] {

        border-radius: 24px
    }

    .d\:rounded-tl-\[14px\] {

        border-top-left-radius: 14px
    }

    .d\:p-1 {

        padding: 0.25rem
    }

    .d\:p-4 {

        padding: 1rem
    }

    .d\:py-\[30px\] {

        padding-top: 30px;

        padding-bottom: 30px
    }

    .d\:\!px-5 {

        padding-left: 1.25rem !important;

        padding-right: 1.25rem !important
    }

    .d\:px-4 {

        padding-left: 1rem;

        padding-right: 1rem
    }

    .d\:px-2\.5 {

        padding-left: 0.625rem;

        padding-right: 0.625rem
    }

    .d\:px-2 {

        padding-left: 0.5rem;

        padding-right: 0.5rem
    }

    .d\:py-2 {

        padding-top: 0.5rem;

        padding-bottom: 0.5rem
    }

    .d\:px-0 {

        padding-left: 0px;

        padding-right: 0px
    }

    .d\:px-1 {

        padding-left: 0.25rem;

        padding-right: 0.25rem
    }

    .d\:\!px-6 {

        padding-left: 1.5rem !important;

        padding-right: 1.5rem !important
    }

    .d\:py-1 {

        padding-top: 0.25rem;

        padding-bottom: 0.25rem
    }

    .d\:px-8 {

        padding-left: 2rem;

        padding-right: 2rem
    }

    .d\:pb-9 {

        padding-bottom: 2.25rem
    }

    .d\:pb-10 {

        padding-bottom: 2.5rem
    }

    .d\:pl-\[12px\] {

        padding-left: 12px
    }

    .d\:pb-\[16px\] {

        padding-bottom: 16px
    }

    .d\:\!pb-6 {

        padding-bottom: 1.5rem !important
    }

    .d\:\!pt-1\.5 {

        padding-top: 0.375rem !important
    }

    .d\:\!pt-1 {

        padding-top: 0.25rem !important
    }

    .d\:\!pt-5 {

        padding-top: 1.25rem !important
    }

    .d\:pb-\[15px\] {

        padding-bottom: 15px
    }

    .d\:pt-0 {

        padding-top: 0px
    }

    .d\:pt-2 {

        padding-top: 0.5rem
    }

    .d\:pb-3 {

        padding-bottom: 0.75rem
    }

    .d\:pb-1 {

        padding-bottom: 0.25rem
    }

    .d\:pr-11 {

        padding-right: 2.75rem
    }
}
@media (max-width: 1208px) {

    .ldo\:hidden {

        display: none
    }
}