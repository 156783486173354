/**
 * Positioning scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import "background";
@import "responsive";

@mixin sticky {
  @include backdrop-filter(8px);
  position: sticky;
  z-index: 1;
  margin-top: auto;
  padding: 1rem 1rem 1rem 1rem;
  bottom: calc(env(safe-area-inset-bottom, 0px) + 0px);

  @include mobile() {
    border-top: var(--thin-border) solid var(--input_border);
    bottom: calc(env(safe-area-inset-bottom, 0px) + 8px);
  }
}

.top-header {
  top: calc(var(--panelheader_height) - var(--thin-border) + env(safe-area-inset-top, 0px));
}

.bottom-tabbar {
  bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom)) !important;
}

.m\:bottom-tabbar {
  @include mobile() {
    bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom)) !important;
  }

  @include tablet() {
    bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom)) !important;
  }
}

.bottom-tabbar-1 {
  bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom) - 1px) !important;
}

.pt-safe {
  padding-bottom: calc(env(safe-area-inset-top, 0px));
}

.pb-safe {
  padding-bottom: calc(env(safe-area-inset-bottom, 0px));
}

.m\:pb-safe {
  @include mobile() {
    padding-bottom: calc(env(safe-area-inset-bottom, 0px));
  }
}

.pb-safe-1 {
  padding-bottom: calc(env(safe-area-inset-bottom, 0px) - 0px);
}

.pt-header {
  padding-top: var(--panelheader_height) !important;
}

.mt-header {
  margin-top: var(--panelheader_height) !important;
}

.mt-header-\[\-1px\] {
  margin-top: calc(var(--panelheader_height) - 0.5px) !important;
}

.mt-header-\[1px\] {
  margin-top: calc(var(--panelheader_height) + 0.5px) !important;
}

.-mt-header {
  margin-top: calc(-1 * var(--panelheader_height));
}

.justify-left {
  justify-content: left;
}

@for $i from 0 through 360 {
  .rotation-#{$i} {
    transform: rotate(#{$i}deg);
  }
}
