/**
 * Background scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import "responsive";

@mixin bg-content($opacity) {
  $light-color: rgb(255, 255, 255);
  $dark-color: rgb(25, 25, 26);
  $fullscreen-light: rgb(247, 247, 247);

  body[scheme="bright_light"] & {
    background-color: rgba($light-color, $opacity) !important;

    &[data-drm="true"] & {
      background-color: rgba($light-color, 1) !important;
    }
  }

  body[scheme="space_gray"] & {
    background-color: rgba($dark-color, $opacity) !important;

    &[data-drm="true"] & {
      background-color: rgba($dark-color, 1) !important;
    }
  }

  @include gt-tablet {
    body[scheme="bright_light"][data-page-type="fullscreen"] & {
      background-color: rgba($fullscreen-light, $opacity) !important;

      &[data-drm="true"] & {
        background-color: rgba($fullscreen-light, 1) !important;
      }
    }
  }
}

@mixin backdrop-filter($blur: 5px) {
  body:not([data-drm="true"]) & {
    backdrop-filter: blur($blur);
    -webkit-backdrop-filter: blur($blur);
  }
}

.filter-invert {
  filter: invert(1);
}

.bg-page {
  background-color: var(--background_page);
}

.bg-page-initial {
  background-color: var(--background_page_initial);
}

.bg-content {
  background-color: var(--background_content) !important;
}

.d\:bg-content {
  @include gt-tablet {
    background-color: var(--background_content) !important;
  }
}

.m\:bg-content {
  @include tablet {
    background-color: var(--background_content);
  }
}

.bg-field {
  background-color: var(--field_background);
}

.bg-input {
  background-color: var(--input_background);
}

.bg-content-85 {
  @include bg-content(0.85);

  &--blur {
    @include bg-content(0.85);
    @include backdrop-filter();
  }
}

.bg-content-75 {
  @include bg-content(0.75);

  &--blur {
    @include bg-content(0.75);
    @include backdrop-filter();
  }
}

.bg-content-50 {
  @include bg-content(0.5);

  &--blur {
    @include bg-content(0.5);
    @include backdrop-filter();
  }
}

.bg-content-25 {
  @include bg-content(0.25);

  &--blur {
    @include bg-content(0.25);
    @include backdrop-filter();
  }
}

.bg-content-15 {
  @include bg-content(0.15);

  &--blur {
    @include bg-content(0.15);
    @include backdrop-filter();
  }
}

.bg-content-tint {
  background-color: var(--content_tint_background);
}

.bg-modal-content {
  background-color: var(--modal_card_background);
}

.bg-button-muted {
  background-color: var(--button_muted_background) !important;;
}

.bg-button-muted-foreground {
  background-color: var(--button_muted_foreground);
}

.bg-button-primary {
  background-color: var(--button_primary_background);
}

.bg-button-secondary {
  background-color: var(--button_secondary_background, var(--vkui--color_background_secondary));
}

.bg-button-commerce {
  background-color: var(--button_commerce_background) !important;
}

.bg-button-commerce--disabled {
  background-color: #4bb34bab !important;
}

.bg-blue-light-transparent {
  background-color: var(--blue_light_transparent);
}

.bg-blue-light-highlight {
  background-color: var(--blue_light_highlight);
}

.bg-green-light-transparent {
  background-color: var(--green_light_transparent);
}

.bg-positive {
  background-color: var(--dynamic_green) !important;

  &-20 {
    background-color: #4bb34b36 !important;
  }
}

.bg-destructive {
  background-color: var(--destructive) !important;

  &-20 {
    background-color: #ff000036 !important;
  }
}

.bg-blue-highlighted {
  background-color: var(--background_text_highlighted);
}

.bg-accent {
  background-color: var(--accent) !important;
}

.bg-vk-button {
  background-color: var(--vk_button_bg);
}

.bg-hover {
  background-color: var(--background_hover) !important;
}

.bg-hover-50 {
  body[scheme=bright_light] & {
    background-color: rgba(0, 0, 0, 0.02) !important;
  }

  body[scheme=space_gray] & {
    background-color: rgba(255, 255, 255, 0.02) !important;
  }
}

.m\:bg-hover {
  @include tablet {
    background-color: var(--background_hover) !important;
  }
}

.hover\:bg-hover {
  &:hover {
    @extend .bg-hover;
  }
}

.bg-highlighted {
  background-color: var(--background_highlighted);
}

.bg-selected {
  background-color: var(--background_selected) !important;
}

.bg-blue-light {
  background-color: var(--blue_light) !important;
}

.bg-dotted {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(90deg, var(--background_content) 26px, transparent 1%) center,
    linear-gradient(var(--background_content) 26px, transparent 1%) center, var(--header_text_secondary);
    background-size: 27px 27px;
  }
}

.bg-button-black {
  color: black !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .6) !important;

  body[scheme=space_gray] & {
    color: white !important;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .6) !important;
  }
}
